@import 'variables';

.StopButton {
    height: 2rem;
    width: 2rem;
    font-size: 1.2rem;

    box-shadow: 0 0 0 0.2rem #1E1E1E;

    &:focus {
        box-shadow:
            0 0 0 0.2rem #1E1E1E,
            0 0 0 0.4rem #67717E,
            0 0 0 0.6rem #FFFFFF;
        outline: none;
    }
}

.StopButton:not(:hover):not(.StopButton--disabled) {
    background-color: #F97953;
}

.StopButton--disabled {
    box-shadow: 0 0 0 0.2rem #9DA4AF;

    &:focus {
        box-shadow:
            0 0 0 0.2rem #9DA4AF,
            0 0 0 0.4rem #67717E,
            0 0 0 0.6rem #FFFFFF;
    }

    svg rect {
        fill: #67717E;
    }
}

.StopButton-svg {
    width: 1rem;
    height: 1rem;
}
