@import 'variables';

.ToggleSwitch {
    width: 4.3rem;
    max-height: 2.5rem;
    background: #c4c4c4;
    border-radius: 1.2rem;
    padding: 0.3rem;
    display: inline-block;
}

.ToggleSwitch:focus {
    box-shadow: 0 0 0 $c2lc-focus-indicator-width $c2lc-focus-indicator-color;
    outline: none;
}

.ToggleSwitch:hover, .ToggleSwitch.ToggleSwitch--checked:hover {
    background-color: #F1AE5B;
}

.ToggleSwitch--checked > div {
    margin-left: 1.8rem;
}

.ToggleSwitch__switch-inner-circle {
    background: #F1F1F1;
    border-radius: 50%;
    display: inline-block;
    margin-bottom: 0.1rem;
    margin-top: -0.05rem;
    padding: 0.2rem;
}

.ToggleSwitch__switch-inner-circle svg {
    height: 1.6rem;
    width: 1.6rem;
    stroke-width: 2.1px;
}
