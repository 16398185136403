@import '_variables';

.ThemeSelector__option {
    position: relative;
    display: grid;
    grid-template-columns: min-content max-content;
    column-gap: 0.5rem;
    padding: 1.4rem 1.5rem;
    font-weight: 500;
    font-size: 18px;
    color: #1E1E1E;
    align-items: center;
    label {
        margin-bottom: 0;
    }
    &:hover {
        background-color: #F5C58A;
    }
    &:focus {
        outline: none;
        box-shadow: 0 0 0 $c2lc-focus-indicator-width #1E1E1E;
        z-index: 1;
    }
}

.ThemeSelector__option-radio {
    pointer-events: none;
}

.ThemeSelector__body {
    position: relative;
    z-index: 0;
    padding: 0.3rem 0.5rem;
}
