@import 'variables';

.ActionsMenuItem {
    position: relative;
    padding: 0.75rem 0.5rem;
    display: grid;
    grid-template-columns: min-content 1fr min-content;
    column-gap: 0.5rem;
    row-gap: 0;
    align-items: center;

    &:focus {
        box-shadow: 0 0 0 $c2lc-focus-indicator-width black;
        outline: none;
        z-index: 1;
    }

    &:hover {
        background-color: #F5C58A;
        .ActionsMenuItem__icon {
            box-shadow: 0 0 0 1px #1e1e1e;
        }
    }
}

.ActionsMenuItem__option {
    background-color: inherit;
    border: 0;
    width: 1rem;
}

.ActionsMenuItem__checkbox {
    pointer-events: none;
}

.ActionsMenuItem__icon {
    text-align: center;
    border-radius: 0.2rem;
    box-shadow: 0 1px 1px 0 #000000, -1px 2px 2px;
    width: 2.5rem;
    height: 2.5rem;
    min-width: 45px;
    min-height: 45px;
    padding: 0;

    svg {
        margin: 0.25rem;
        width: 2rem;
        height: 2rem;
    }
}

.ActionsMenuItem__icon--loop {
    box-shadow: none;
    height: 2.5rem;
    width: 3.3rem;
    min-width: 60px;
    
    svg {
        margin: 0rem;
        height: 2.5rem;
        width: 3.3rem;
    }
}

.ActionsMenuItem__icon--forward1, .ActionsMenuItem__icon--forward2, .ActionsMenuItem__icon--forward3 {
    background-color: $c2lc-forward-command-color;
}

.ActionsMenuItem__icon--backward1, .ActionsMenuItem__icon--backward2, .ActionsMenuItem__icon--backward3 {
    background-color: $c2lc-backward-command-color;
}

.ActionsMenuItem__icon--left45, .ActionsMenuItem__icon--left90, .ActionsMenuItem__icon--left180 {
    background-color: $c2lc-left-command-color;
}

.ActionsMenuItem__icon--right45, .ActionsMenuItem__icon--right90, .ActionsMenuItem__icon--right180 {
    background-color: $c2lc-right-command-color;
}
