@import 'variables';

.RefreshButton svg path {
    stroke: none;
}

.RefreshButton {
    background-color: inherit;
    margin: auto 0;

    svg {
        path {
            fill: #1E1E1E;
        }
        path.inner-circle {
            fill: #F97953;
        }
    }

    &:focus {
        box-shadow: 0 0 0 $c2lc-focus-indicator-width #FFFFFF;
    }

    &:hover:not(.RefreshButton--disabled) {
        background-color: inherit;
        svg path.inner-circle {
            fill: #F1AE5B;
        }
    }
    
    &:hover:not(:focus) {
        box-shadow: none;
    }
}

.RefreshButton.RefreshButton--disabled,
.RefreshButton.RefreshButton--disabled:hover,
.RefreshButton.RefreshButton--disabled:active {
    cursor: default;
}

.RefreshButton--disabled svg {
    .refresh-icon {
        fill: #67717E;
        stroke: #9DA4AF;        
    }

    circle {
        stroke: none;
        fill: #9DA4AF;
    }

    path.inner-circle {
        fill: #B9BEC6;
    }

    rect.refresh-icon {
        stroke: #9DA4AF;
        fill: #B9BEC6;
    }
}
