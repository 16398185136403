@import 'variables';

.DashConnectionErrorModal {
    width: 35rem;
}

.DashConnectionErrorModal__content {
    padding: 3rem 4rem;
}

.DashConnectionErrorModal__header {
    font-weight: bold;
}

.DashConnectionErrorModal__error-svg {
    width: 2rem;
    height: 2rem;
    margin-right: 0.5rem;
    margin-bottom: 0.5rem;
}

.DashConnectionErrorModal__body {
    margin-top: 1rem;
}

.DashConnectionErrorModal__footer {
    margin-top: 3rem;
    text-align: center;
}

.DashConnectionErrorModal__option-button {
    min-width: 11rem;
}
