@import 'variables';

body {
    background-color: #FFF;
    overflow-y: hidden;
    overflow-x: hidden;
    touch-action: manipulation;
    user-select: none;
}

.App__container {
    background-color: #FFF;
    box-sizing: border-box;
    display: grid;
    grid-template-columns: 1fr min-content minmax(auto, calc(#{$c2lc-scene-border-width} + #{$c2lc-scene-border-width} + #{$c2lc-scene-row-header-width} + #{$c2lc-scene-width} + #{$c2lc-scene-space-for-scrollbar} + #{$c2lc-app-scene-container-margin-right})) min-content 1fr;
    grid-template-rows: auto auto auto 1fr 4.6rem;
    row-gap: 0;
    height: 100vh;
    overflow: auto;
    // On mobile Safari, limit the height of the App to the browser content
    // area. See:
    // https://allthingssmitty.com/2020/05/11/css-fix-for-100vh-in-mobile-webkit/
    max-height: -webkit-fill-available;
}

.App__notificationArea {
    grid-column-start: 1;
    grid-column-end: 6;
    grid-row-start: 1;
    grid-row-end: 2;
}

.App__header {
    background-color: #FFF;
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 2;
    grid-row-end: 3;
    margin: 0.5rem 0.25rem 0.75rem 0.5rem;
}

.App__header-row {
    display: flex;
    flex-direction: column;
}

.App__header-menu {
    background-color: #CCE6E2;
    border-radius: 3px;
    display: grid;
    grid-template-columns: min-content min-content;
    justify-content: center;
    column-gap: 0.7rem;
    row-gap: 0.7rem;
    padding: 1rem 0;
}

.App__header-menu > .IconButton:not(.App__ActionsMenu__toggle-button) {
    svg path {
        fill: #1E1E1E;
    }
}

.App__header-audio-toggle {
    display: flex;
    align-self: center;
    margin: 0 1rem;
    margin-bottom: 0.5rem;
}

.App__logo-container {
    font-size: 3.1rem;
    line-height: 3.3rem;
    padding: 0;
    width: 7.2rem;

    a {
        border: 1px solid transparent;
        border-radius: 2px;
        outline: none;

        &:focus {
            border-color: #000000;
        }
    }

    svg {
        padding: 0.25rem;
        &:hover {
            path.logo-tile {
                fill: #F1AE5B;
            }
        }
    }
}

.App__PrivacyButtonContainer {
    margin-left: 0.5rem;
    margin-bottom: 0.25rem;
}

.App__PrivacyModal__toggle-button {
    background-color: inherit;
    border: 1px solid transparent;
    border-radius: 2px;
    font-size: 0.8rem;
    outline: none;
    text-decoration: underline;

    &:focus {
        border: 1px solid #000000;
    }
}


/* Dash connection removed for version 0.5
.App__audio-toggle-switch {
    margin-right: 1.5rem;
}
*/

.App__bluetooth-api-warning-section {
    margin-top: 1.5rem;
}

.App__scene-container {
    position: relative;
    margin-top: 0.25rem;
    margin-right: $c2lc-app-scene-container-margin-right;
    margin-bottom: 0.25rem;
    grid-column-start: 3;
    grid-column-end: 4;
    grid-row-start: 2;
    grid-row-end: 4;
    min-height: 12rem;
    min-width: 19.5rem; 
}

.App__world-container {
    grid-column-start: 4;
    grid-column-end: 5;
    grid-row-start: 2;
    grid-row-end: 4;
    border-radius: 3px;
    margin-right: 0.5rem;
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
    background-color: #CCE6E2;
}

.App__world-selector {
    display: grid;
    grid-template-columns: min-content min-content;
    justify-content: center;
    column-gap: 0.7rem;
    padding: 0.5rem 0;
}

.App__PenDownToggleSwitch-container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.25rem 0;
    border-top: 1px solid #88C3BC;
}

.App__ActionsMenu__header {
    background-color: #CCE6E2;
    display: grid;
    grid-template-columns: min-content 1fr;
    padding: 0.4rem;
    padding-left: 1.2rem;
    padding-right: 1.2rem;
    position:relative;
}

.App__ActionsMenu__header-heading {
    font-size: inherit;
    font-weight: bold;
    grid-column-end: 2;
    grid-column-start: 1;
    margin: auto;
}

.App__command-palette {
    background-color: #CCE6E2;
    border: 2px solid #CCE6E2;
    border-radius: 0.2rem;
    text-align: center;
    margin: 0 0.25rem 0.25rem 0.5rem;
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 3;
    grid-row-end: 5;
    display: flex;
    flex-direction: column;
    grid-template-rows: min-content min-content auto;
}

.App__command-palette-command-container {
    background-color: #19282F;
    border-radius: 3px;
    height: 100%;
    width: 100%;
    overflow-y: auto;
    grid-row-start: 3;
    grid-row-end: 4;
}

.App__command-palette-section {
    background-color: #30444E;
    border-radius: 5px;
    box-shadow: 0px 3px 1px #19282F;
    margin-bottom: .5rem;
    width: 100%;
    &:last-of-type {
        padding-bottom: 1rem;
    }
}

.App__command-palette-section-heading-container {
    border-bottom: 2px solid #818A98;
    width: 100%;
}

.App__command-palette-section-heading {
    color: #F1AE5B;
    font-size: .9rem;
    margin-bottom: 0;
    margin-top: 0;
    padding-left: .5rem;
    padding-bottom: .6rem;
    padding-top: .6rem;
    text-align: left;
}

.App__command-palette-section-body {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 0.4rem;
    padding-top: 1rem;
}

.App__command-palette-command-container .btn-command-block--loop:hover {
    svg {
        path.filled-bracket {
            fill: #878787;
        }
    }
}

.App__command-palette-commands {
    display: flex;
    height: auto;
    flex-direction: column;
    margin: 0 auto;
}

.App__command-palette-commands > button,
.App__command-palette-commands > div {
    margin-bottom: 1rem;
}

.App__command-palette-controls > button:not(.btn-command-block--loop) {
    width: 3.3rem;
    height: 2.5rem;
    svg {
        width: 3.3rem;
        height: 2.5rem;
    }
}

.App__command-palette-controls > button.btn-command-block--loop {
    width: 3.8rem;
    height: 2.9rem;
    svg {
        padding-top: 0.1rem;
        width: 3.4rem;
        height: 2.5rem;
    }
}

.App__program-block-editor {
    margin-right: 0.5rem;
    margin-bottom: 0.25rem;
    grid-column-start: 3;
    grid-column-end: 5;
    grid-row-start: 4;
    grid-row-end: 5;
    height: auto;
}

.App__playAndShare-background {
    background-color: #67717E;
    grid-column-start: 1;
    grid-column-end: 6;
    grid-row-start: 5;
    grid-row-end: 6;
    box-shadow: inset 0px 7px 3px -3px rgba(0, 0, 0, 0.25);
}

.App__playAndShare-container {
    padding: 0 1rem;
    grid-column-start: 2;
    grid-column-end: 5;
    grid-row-start: 5;
    grid-row-end: 6;
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.App__playControl-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    grid-column-start: 1;
    grid-column-end: 2;
    margin: auto 0;
}

.App__playButton-container {
    display: flex;
    gap: 0.5rem;
}

.App__playControlButton {
    margin-right: 1.5rem;
    &.StopButton {
        margin-right: 1.9rem;
    }
}


.App__shareButton-container {
    align-items: flex-end;
    display: flex;
    flex-direction: column;
    grid-column-start: 2;
    grid-column-end: 3;
    justify-content: center;
}


.App__ShareButton {
    background-color: #CCE6E2;
    border: 0;
    border-radius: 0.2rem;
    color: #1E1E1E;
    font-weight: 400;
    padding: 0.5rem 2rem;
    width: 10rem;
    display:flex;
    column-gap: 0.5rem;
    align-items: center;
    outline: 0;

    &:active {
        background-color: #ED972C;
    }

    &:focus {
        box-shadow:
            0 0 0 0.1rem #30444E,
            0 0 0 $c2lc-focus-indicator-width #FFFFFF;
    }

    &:hover {
        background-color: #F5C58A;
        color: #1E1E1E;
        svg path {
            fill: #1E1E1E;
        }
    }
}

.App__ShareButton__icon {
    width: 1rem;
    height: 1rem;
    path {
        fill: #1E1E1E;
    }
}

.App__ShareButton__label {
    margin: auto;
}

@media only screen and (max-width: 768px) {
    .App__command-palette-commands {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .App__playAndShare-container {
        padding-bottom: 2rem;
    }
}

@media only screen and (max-height: 800px) {
    .WorldSelector {
        height: calc(80vh - 19rem);
    }
}

// For iPad landscape orientation
@media all and (device-width: 768px) and (device-height: 1024px) and (orientation:landscape) {
    .WorldSelector {
        height: calc(80vh - 21rem);
    }
}

@media only screen and (max-width: 414px) {
    body {
        overflow: scroll;
    }
}
