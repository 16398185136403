@import '_variables';

.KeyboardInputModal__content {
    max-width: 45rem;
    padding: 0;
    padding-top: 0.5rem;
}

.KeyboardInputModal__content__toggleBar {
    align-items: center;
    display: flex;
    flex-direction: row;
    column-gap: 2rem;
    margin-left: 3rem;
}

.KeyboardInputModal__content__toggleBar__label {
    font-weight: bold;
}

.KeyboardInputModal__content__toggleBar__toggle {
    align-items: center;
    display: flex;
    flex-direction: row;
    column-gap: 0.5rem;
}

.KeyboardInputModal__content__toggle.ToggleSwitch {
    .ToggleSwitch__switch-inner-circle {
        height: 1.9rem;
        width: 1.9rem;
        margin-bottom: inherit;
        margin-top: inherit;
    }
}

.KeyboardInputModal__content__toggle.ToggleSwitch--checked {
    background-color: #4C9990;
}

.KeyboardInputModal__content__schemeDropdown {
    margin-top: 0.5rem;
    margin-bottom: 1rem;
    margin-left: 3rem;
    max-width: 20rem;
    padding: 0.25rem;
}

.KeyboardInputModal__content__list {

    background-color: #F1F2F4;
    padding: 1rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 1.5rem;
    row-gap: 1.5rem;
    margin-block-end: 0;
}

@media only screen and (max-width: 1024px) {
    .KeyboardInputModal__content__list {
        display: flex;
        flex-direction: column;
        column-gap: 0;
    }
}

.KeyboardInputModal__binding {
    display: grid;
    grid-template-columns: 7.5rem auto;
    align-items: flex-start;
    column-gap: 1rem;
    row-gap: 1rem;
}

.KeyboardInputModal__binding__keyCombo {
    align-items: center;
    display: flex;
    flex-direction: row;
    column-gap: 0;
}

.KeyboardInputModal__binding__icon {
    border: 2px solid #B9BEC6;
    background-color: white;
    border-radius: 3px;
    box-shadow: 0 3px 1px 0 #B9BEC6;
    font-weight: bold;
    height: 2.5rem;
    width: 2.5rem;

    display: flex;
    align-items: center;
    justify-content: center;
}
