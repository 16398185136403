.modal-opened {
    overflow: hidden;
}

.Modal__container {
    z-index: 100;
    background: rgba(0, 0, 0, 0.3);
    display: none;
    position: fixed;
    overflow-y: auto;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.Modal__container.active {
    display: block;
}

.Modal {
    box-sizing: border-box;
    border-radius: 0.3rem;
    background-color: #FFF;
    position: absolute;
    top: 45vh;
    left: 50vw;
    transform: translateX(-50%) translateY(-50%);
    min-height: auto;
    width: max-content;
    box-shadow: 0 19px 38px rgba(0, 0, 0, 0.12), 0 15px 12px rgba(0, 0, 0, 0.22);
}
