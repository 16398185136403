@import 'variables';

/* Check _variables.scss for most colours. */
body.default-theme {
    // Change this in the theme to avoid side effects in other themes.
    .App__command-palette-command-container {
        &::-webkit-scrollbar {
            background-color: #30444E;
        }
        &::-webkit-scrollbar-thumb {
            background-color: #CCE6E2;
        }
    }

    .WorldSelector .WorldIcon {
        box-shadow: inset 0 .1rem .2rem rgba(0, 0, 0, 0.5);
    }

    .WorldSelector button.btn.WorldIcon--disabled {
        background-color: #B9BEC6;
    }

    .WorldSelector .WorldIcon--selected:not(:hover) {
        background-color: #4C9990;
    }
}


/* Light Theme */
body.light-theme {
    background-color: #FFF;

    .App__container {
        background-color: #FFF;
    }

    .App__command-palette-command-container {
        background-color: #CCE6E2;
    }

    .App__command-palette-heading {
        color: #30444E;
    }

    .App__command-palette-section {
        background-color: #EBFAF8;
    }

    .App__command-palette-section-heading {
        color: #000000;
    }

    .App__command-palette-command-container .btn-command-block--loop {
        svg {
            path.filled-bracket {
                fill: #30444E;
            }
            path.inner-arrow {
                stroke: #FFFFFF;
            }
        }

        &:hover {
            path.filled-bracket {
                fill: #4B4B4B;
            }
        }
    }

    .App__command-palette-command-container .btn-command-block--loop, .ActionsMenuItem__icon--loop {
        svg {
            path.filled-bracket {
                fill: #787878;
            }
            path.inner-arrow {
                stroke: #F1F2F4;
            }
        }
    }

    .App__command-palette-section {
        box-shadow: 0px 3px 1px #AAD5CF;
    }

    button.btn.command-block:focus {
        border: 2px solid #EBFAF8;
        box-shadow: 0 0 0 $c2lc-focus-indicator-width black;
    }

    // Use both IDs and classnames here to overpower styles inherited from react-bootstrap's $theme-colors
    #command-block--backward1, .btn-command-block--backward1,
    #command-block--backward2, .btn-command-block--backward2,
    #command-block--backward3, .btn-command-block--backward3,
    #command-block--forward1, .btn-command-block--forward1,
    #command-block--forward2, .btn-command-block--forward2,
    #command-block--forward3, .btn-command-block--forward3,
    #command-block--left45, .btn-command-block--left45,
    #command-block--left90, .btn-command-block--left90,
    #command-block--left180, .btn-command-block--left180,
    #command-block--right45, .btn-command-block--right45,
    #command-block--right90, .btn-command-block--right90,
    #command-block--right180, .btn-command-block--right180,
    #command-block-loop, .btn-command-block--loop
    {
        svg {
            path, circle, rect {
                stroke: white;
            }
            circle, path.filled {
                fill: white;
            }
        }

        &.command-block--pressed {
            border: 2px solid #30444E;
        }
    }

    .ActionsMenuItem__icon:not(.ActionsMenuItem__icon--hidden):not(.ActionsMenuItem__icon--loop)
    {
        svg {
            path, circle, rect {
                stroke: white;
            }
            circle, path.filled {
                fill: white;
            }
        }
    }

    // Use both IDs and classnames here to overpower styles inherited from react-bootstrap's $theme-colors
    #command-block--backward1, .btn-command-block--backward1,
    #command-block--backward2, .btn-command-block--backward2,
    #command-block--backward3, .btn-command-block--backward3 {
        background-color: #33717F;

        &:hover, &:focus {
            background-color: #07586A;
        }
    }

    .ActionsMenuItem__icon--backward1, .ActionsMenuItem__icon--backward2, .ActionsMenuItem__icon--backward3 {
        &:not(.ActionsMenuItem__icon--disabled) {
            background-color: #33717F;
        }
    }

    // Use both IDs and classnames here to overpower styles inherited from react-bootstrap's $theme-colors
    #command-block--forward1, .btn-command-block--forward1,
    #command-block--forward2, .btn-command-block--forward2,
    #command-block--forward3, .btn-command-block--forward3 {
        background-color: #4C9990;

        &:hover, &:focus {
            background-color: #16796D;
        }
    }

    .ActionsMenuItem__icon--forward1, .ActionsMenuItem__icon--forward2 .ActionsMenuItem__icon--forward3 {
        &:not(.ActionsMenuItem__icon--disabled) {
            background-color: #4C9990;
        }
    }

    // Use both IDs and classnames here to overpower styles inherited from react-bootstrap's $theme-colors
    #command-block--left45, .btn-command-block--left45,
    #command-block--left90, .btn-command-block--left90,
    #command-block--left180, .btn-command-block--left180 {
        background-color: #D37D12;

        &:hover, &:focus {
            background-color: #AE6100;
        }
    }

    .ActionsMenuItem__icon--left45, .ActionsMenuItem__icon--left90, .ActionsMenuItem__icon--left180 {
        &:not(.ActionsMenuItem__icon--disabled) {
            background-color: #D37D12;
        }
    }

    // Use both IDs and classnames here to overpower styles inherited from react-bootstrap's $theme-colors
    #command-block--right45, .btn-command-block--right45,
    #command-block--right90, .btn-command-block--right90,
    #command-block--right180, .btn-command-block--right180 {
        background-color: #C13925;

        &:hover, &:focus {
            background-color: #952A1B;
        }
    }

    button.command-block.btn-command-block--startLoop,
    button.command-block.btn-command-block--endLoop {
        background-color: #9D9D9D;
        .command-block-loop-label-container {
            background-color: #858383;
            color: #FFFFFF;
        }
        &:focus {
            border: 0.2rem solid #30444E;
            box-shadow: none;
        }
        &:hover {
            background-color: #787878;
            .command-block-loop-label-container {
                background-color: #5E5D5D;
            }
        }
    }

    .ProgramBlockEditor__loopContainer--focused:not(.ProgramBlockEditor__loopContainer-active-outline) {
        box-shadow: 0 0 0.1px 0.2rem #30444E;
    }

    .ProgramBlockEditor__loopContainer--active {
        z-index: 2;
        background-color: #EAEEF3;
    }

    .ProgramBlockEditor__loopContainer-active-outline {
        box-shadow: 0 0 0.1px 0.4rem #30444E;
    }

    .ActionsMenuItem__icon--right45, .ActionsMenuItem__icon--right90, .ActionsMenuItem__icon--right180 {
        &:not(.ActionsMenuItem__icon--disabled) {
            background-color: #C13925;
        }
    }

    button.ProgramBlockEditor__program-block--updated.btn-command-block--forward1,
    button.ProgramBlockEditor__program-block--updated.btn-command-block--forward2,
    button.ProgramBlockEditor__program-block--updated.btn-command-block--forward3 {
        @include updatedProgramAnimation(#16796D, 'forward', 'light');
    }

    button.ProgramBlockEditor__program-block--updated.btn-command-block--backward1,
    button.ProgramBlockEditor__program-block--updated.btn-command-block--backward2,
    button.ProgramBlockEditor__program-block--updated.btn-command-block--backward3 {
        @include updatedProgramAnimation(#07586A, 'backward', 'light');
    }

    button.ProgramBlockEditor__program-block--updated.btn-command-block--left45,
    button.ProgramBlockEditor__program-block--updated.btn-command-block--left90,
    button.ProgramBlockEditor__program-block--updated.btn-command-block--left180 {
        @include updatedProgramAnimation(#AE6100, 'left', 'light');
    }

    button.ProgramBlockEditor__program-block--updated.btn-command-block--right45,
    button.ProgramBlockEditor__program-block--updated.btn-command-block--right90,
    button.ProgramBlockEditor__program-block--updated.btn-command-block--right180 {
        @include updatedProgramAnimation(#952A1B, 'right', 'light');
    }

    .Scene {
        background-color: white;
    }

    .ProgramBlockEditor__container {
        border: 2px solid #CCE6E2;
        background-color: #CCE6E2;
    }

    .ProgramBlockEditor__header {
        background-color: #CCE6E2;
        color: #1E1E1E;
    }

    .ProgramBlockEditor__program-sequence-scroll-container {
        background-color: white;
    }

    .ProgramBlockEditor__start-indicator {
        background-color: black;
    }

    button.btn.WorldIcon {
        border: 1px solid rgba(0, 0, 0, 0.25);
    }

    .WorldSelector button.btn.WorldIcon:focus {
        box-shadow:
            0 0 0 0.1rem #CCE6E2,
            0 0 0 0.2rem #30444E;

    }

    @keyframes pausedLightAnimation {
        0% {box-shadow: 0 0 0 $c2lc-paused-button-outer-width #30444E;}
        50% {box-shadow: 0 0 0 $c2lc-active-button-width #30444E;}
        100% {box-shadow: 0 0 0 $c2lc-paused-button-outer-width #30444E;}
    }

    button.ProgramBlockEditor__program-block--active:not(.btn-command-block--startLoop) {
        box-shadow: 0 0 0 6px #30444E;
    }

    button.ProgramBlockEditor__program-block--paused {
        animation: pausedLightAnimation 2s infinite;
    }

    .ActionPanel__background {
        svg {
            path {
                fill: #F1F2F4;
                stroke: #505862;
            }
        }
    }

    button.btn.ActionPanel__action-buttons {
        background-color: #D5D8DD;
        svg {
            path.stroked {
                stroke: #505862;
            }
            path.filled {
                fill: #505862;
            }
        }

        &:hover, &:focus:not(:disabled) {
            background-color: #505862;
            svg {
                path.stroked {
                    stroke: #F1F2F4;
                }
                path.filled {
                    fill: #F1F2F4;
                }
            }
        }

        &:focus {
            box-shadow:
                0 0 0 0.1rem #F1F2F4,
                0 0 0 $c2lc-focus-indicator-width black;
        }
    }

    button.btn.ActionPanel__action-buttons--disabled {
        background-color: #D5D8DD;
        svg {
            path.stroked {
                stroke: #F1F2F4;
            }
            path.filled {
                fill: #F1F2F4;
            }
        }
    }

    .AddNode__collapsed-icon {
        background-color: #505862;
        svg path {
            stroke: white;
            stroke-width: 6px;
        }
    }

    button.btn.AddNode__expanded-button {
        background-color: white;
        border: $c2lc-add-node-expanded-border-width solid #505862;

        &:not(.AddNode__expanded-button--isDragTarget) {
            box-shadow: none;
        }

        svg {
            path {
                stroke: #505862;
            }
        }

        &:hover {
            background-color: #e5e5e5;
        }

        &:focus {
            border: $c2lc-add-node-expanded-border-width solid white;
            box-shadow: 0 0 0 $c2lc-focus-indicator-width black;
        }
    }

    .App__playAndShare-background {
        background-color: #D5D8DD;
    }

    .RefreshButton {
        &:focus {
            box-shadow: 0 0 0 $c2lc-focus-indicator-width #1E1E1E;
        }
    }

    .RefreshButton--disabled svg {
        .refresh-icon {
            fill: #505862;
            stroke: #9DA4AF;
        }

        circle {
            stroke: none;
            fill: #67717E;
        }

        rect.refresh-icon {
            stroke: #505862;
            fill: #B9BEC6;
        }
    }

    button.btn.PlayButton:focus {
        box-shadow: 0 0 0 $c2lc-focus-indicator-width $c2lc-focus-indicator-color;
    }

    button.btn.PlayButton--disabled {
        svg {
            circle {
                fill: #67717E;
            }
            path.triangle {
                fill: #505862;
            }
        }
    }

    .StopButton:focus {
        box-shadow:
            0 0 0 0.2rem #1E1E1E,
            0 0 0 0.4rem #D5D8DD,
            0 0 0 0.6rem #1E1E1E;
    }

    .StopButton--disabled {
        box-shadow: 0 0 0 0.2rem #67717E;

        svg rect {
            fill: #505862;
        }

        &:focus {
            box-shadow:
                0 0 0 0.2rem #67717E,
                0 0 0 0.4rem #D5D8DD,
                0 0 0 0.6rem #1E1E1E;
        }
    }

    .ProgramSpeedController__slider {
        &:hover {
            &::-webkit-slider-thumb {
                background-color: #F1AE5B;
                box-shadow: -8rem 0 0 8rem #F1AE5B;
            }

            &::-moz-range-thumb {
                background-color: #F1AE5B;
                box-shadow: -8rem 0 0 8rem #F1AE5B;
            }

            &::-moz-range-progress {
                background-color: #F1AE5B;
            }

            &::-ms-fill-lower {
                background-color: #F1AE5B;
            }
        }

        &:focus {
            outline-color: $c2lc-focus-indicator-color;
        }

        &::-webkit-slider-thumb {
            background-color: #33717F;
            box-shadow: -8rem 0 0 8rem #33717F;
        }

        &::-moz-range-thumb {
            background-color: #33717F;
            box-shadow: -8rem 0 0 8rem #33717F;
        }

        &::-moz-range-progress {
            background-color: #33717F;
        }

        &::-ms-fill-lower {
            background-color: #33717F;
        }
    }

    .App__ShareButton {
        background-color: #33717F;
        color: #FFFFFF;
        &:focus {
            box-shadow:
                0 0 0 0.1rem #D5D8DD,
                0 0 0 $c2lc-focus-indicator-width #1E1E1E;
        }
        &:hover {
            background-color: #F5C58A;
            color: #1E1E1E;
            svg path {
                fill: #1E1E1E;
            }
        }
    }

    .App__ShareButton__icon {
        path {
            fill: #FFFFFF;
        }
    }
}


/* Dark Theme */
body.dark-theme {
    background-color: #18252C;

    .App__container {
        background-color: #18252C;
    }

    .App__header-menu {
        background-color: #30444E;
    }

    .IconButton {
        &:focus {
            box-shadow: 0 0 0 $c2lc-focus-indicator-width white;
        }
    }

    .App__header {
        background-color: #18252C;
    }

    .App__logo-container {
        a:focus {
            border-color: #FFFFFF;
        }

        svg {
            &:hover {
                path.logo-title-letter {
                    stroke: black;
                    fill: black;
                }
            }
        }
    }

    .App__PrivacyModal__toggle-button {
        color: white;
    }

    .App__PrivacyModal__toggle-button:focus {
        border-color: #ffffff;
    }

    .App__command-palette {
        background-color: #33717F;
        border: 2px solid #33717F;
    }

    .App__command-palette-command-container {
        background-color: #22343C;
        &::-webkit-scrollbar {
            background-color: #30444E;
        }
        &::-webkit-scrollbar-thumb {
            background-color: #4C9990;
        }
    }

    .App__command-palette-heading {
        color: #F1AE5B;
    }

    .App__command-palette-section {
        border: 2px solid #19282F;
    }

    .App__ActionsMenu__header {
        background-color: #30444E;
        color: white;
    }

    .App__ActionsMenu__menu {
        background-color: #22343C;
        border: 2px solid #33717F;
        box-shadow: 0px 4px 4px #22262A;
        color: white;
    }

    .App__world-container {
        background-color: #30444E;
        border: 2px solid #33717F;
    }

    .App__PenDownToggleSwitch-container {
        border-color: #33717F;
    }

    button.btn.command-block.command-block--pressed,
    button.btn.command-block.command-block--pressed:hover,
    button.btn.command-block.command-block--pressed:active {
        border: $c2lc-button-border-width solid white;
    }

    .App__scene-container {
        border-color: #33717F;
    }

    .Scene__container {
        background-color: #30444E;
        border: $c2lc-scene-border-width solid #33717F;
    }

    .Scene {
        background-color: #22343C;
    }

    .Scene__header-corner {
        background-color: #33717F;
    }

    .Scene__column-header, .Scene__row-header {
        fill: #33717F;
    }

    text.Scene__grid-label {
        fill: white;
    }

    .PenDownToggleSwitch:focus {
        box-shadow: 0 0 0 $c2lc-focus-indicator-width white;
    }

    .CharacterPositionController {
        background-color: #30444E;
        border-top: 1px solid #33717F;
    }

    .CharacterPositionController__character-turn-positions {
        svg path.filled {
            fill: #4C9990;
            stroke: none;
        }
        .CharacterPositionController__character-position-button--disabled {
            path.filled {
                fill: #505862;
            }
        }
        &:focus {
            outline: $c2lc-focus-indicator-width solid white;
        }
    }

    .CharacterPositionController__character-position-button {
        stroke: #4C9990;
        &:focus {
            outline: $c2lc-focus-indicator-width solid white;
        }
    }

    .ProgramBlock__character-position-coordinate-box:focus {
        outline: $c2lc-focus-indicator-width solid white;
    }

    .CharacterPositionController__character-position-button--disabled {
        cursor: default;
        stroke: #505862;
    }

    .WorldSelector {
        background-color: #33717F;
    }

    .WorldSelector .WorldIcon {
        background-color: #30444E;
        box-shadow: inset 0 .1rem .2rem rgba(0, 0, 0, 0.5);
    }

    .WorldSelector button.btn.WorldIcon:focus {
        box-shadow: 0 0 0 $c2lc-focus-indicator-width white;
    }

    .WorldSelector button.btn.WorldIcon--disabled {
        background-color: #505862;
    }

    .WorldSelector .WorldIcon--selected:not(:hover) {
        background-color: #4C9990;
    }

    .ProgramBlockEditor__container {
        background-color: #33717F;
        border: 2px solid #33717F;
    }

    .ProgramBlockEditor__header {
        background-color: #30444E;
        color: #EBFAF8;
    }

    .ProgramBlockEditor__add-node-toggle-switch:focus {
        box-shadow: 0 0 0 $c2lc-focus-indicator-width white;
    }

    .ProgramBlockEditor__program-sequence-scroll-container {
        background-color: #22343C;
    }

    @keyframes pausedDarkAnimation {
        0% {box-shadow: 0 0 0 $c2lc-paused-button-outer-width white;}
        50% {box-shadow: 0 0 0 $c2lc-active-button-width white;}
        100% {box-shadow: 0 0 0 $c2lc-paused-button-outer-width white;}
    }

    button.ProgramBlockEditor__program-block--paused {
        animation: pausedDarkAnimation 2s infinite;
    }

    button.ProgramBlockEditor__program-block--active:not(.btn-command-block--startLoop) {
        box-shadow: 0 0 0 $c2lc-active-button-width white;
    }

    .AddNode__collapsed-icon {
        background-color: white;
        svg {
            stroke: black;
            fill: black;
        }
    }

    .AddNode__expanded-button {
        border: $c2lc-add-node-expanded-border-width solid #D5D8DD;
        background-color: #22343C;
        box-shadow: none;
        svg path {
            stroke: #D5D8DD;
        }
        &:hover {
            svg path {
                stroke: black;
            }
        }

        &:focus {
            box-shadow: 0 0 0 $c2lc-focus-indicator-width white;
        }
    }

    .StopButton:focus {
        box-shadow:
            0 0 0 0.2rem #1E1E1E,
            0 0 0 0.4rem #67717E,
            0 0 0 0.6rem #FFFFFF;
    }

    .StopButton.StopButton--disabled:focus {
        box-shadow:
            0 0 0 0.2rem #9DA4AF,
            0 0 0 0.4rem #67717E,
            0 0 0 0.6rem #FFFFFF;
    }

    .App__ShareButton {
        &:focus {
            box-shadow:
                0 0 0 0.1rem #30444E,
                0 0 0 $c2lc-focus-indicator-width white;
        }
    }

    .TilePanel__tile:focus {
        outline-color: white;
    }

    .TilePanel__tile--selected {
        border-color: white;
    }
}

/* Grayscale Theme */
body.gray-theme {
    background-color: #FFF;

    .App__container {
        background-color: #FFF;
    }

    .App__logo-container {
        a {
            outline-color: #000000;
        }

        svg {
            &:hover {
                path.logo-tile {
                    fill: #505862;
                }
                path.logo-title-letter {
                    stroke: #FFFFFF;
                    fill: #FFFFFF;
                }
            }
        }
    }

    .App__world-container {
        background-color: #D5D8DD;
    }

    .App__PenDownToggleSwitch-container {
        border-color: #9DA4AF;
    }

    .App__command-palette {
        background-color: #D5D8DD;
        border: 2px solid #D5D8DD;
    }

    .App__command-palette-command-container {
        background-color: #F1F2F4;
    }

    .App__command-palette-command-container {
        background-color: #D5D8DD;

        &::-webkit-scrollbar {
            background-color: #F1F2F4;
        }
        &::-webkit-scrollbar-thumb {
            background-color: #D5D8DD;
        }
    }

    .App__command-palette-heading {
        color: #818A98;
    }

    .App__command-palette-section {
        background-color: #F1F2F4;
        box-shadow: 0px 3px 1px #9DA4AF;
    }

    .App__command-palette-section-heading {
        color: #818A98;
    }

    .App__command-palette-command-container .btn-command-block--loop, .ActionsMenuItem__icon--loop {
        svg {
            path.filled-bracket {
                fill: #818A98;

            }
            path.inner-arrow {
                stroke: #F1F2F4;
            }
        }
    }

    .App__command-palette-command-container .btn-command-block--loop:hover {
        svg {
            path.filled-bracket {
                fill: #505862;
            }
        }
    }

    .App__header-menu {
        background-color: #D5D8DD;
    }

    .App__header-menu > .IconButton:not(.App__ActionsMenu__toggle-button) {
        svg path {
            fill: #FFFFFF;
        }
    }

    .IconButton {
        &:not(.IconButton--disabled) {
            background-color: #818A98;

            .App__header-actionsMenu-icon {
                path {
                    stroke: white;
                }
            }
        }

        &:hover:not(.IconButton--disabled) {
            background-color: #505862;
        }

        &:focus {
            box-shadow: 0 0 0 $c2lc-focus-indicator-width #505862;
        }

        .App__header-keyboard-icon {
            path {
                fill: white;
            }
        }

        .App__header-theme-icon {
            path {
                fill: white;
            }
        }

        .App__world-selector-icon {
            stroke: white;
            rect,
            path {
                stroke: white;
            }
            circle {
                fill: white;
            }
        }

        .App__header-theme-icon {
            path {
                fill: white;
            }
        }
    }

    .IconButton--disabled {
        background-color: #818A98;
        svg path {
            stroke: #D5D8DD;
        }
    }


    .KeyboardInputModal__content__toggle {
        background-color: #B9BEC6;

        &:focus {
            box-shadow: 0 0 0 $c2lc-focus-indicator-width #505862;
        }

        &.ToggleSwitch--checked{
            background-color: #818A98;
        }

        &:hover {
            background-color: #505862;
            .ToggleSwitch__switch-inner-circle {
                background-color: #B9BEC6;
            }
        }
    }

    button.btn.ModalWithFooter__secondaryButton,
    button.btn.ModalWithFooter__primaryButton,
    .TextButton {
        background-color: #aaa;
        color: black;

        &:hover {
            background-color:#D5D8DD;
        }

        &:focus {
            box-shadow:
                0 0 0 0.15rem #d5d8dd,
                0 0 0 0.25rem #505862;
        }
    }

    .KeyboardInputModal__content__toggle.ToggleSwitch {
        background-color: #B9BEC6;

        &:focus {
            box-shadow: 0 0 0 $c2lc-focus-indicator-width #505862;
        }

        &:hover {
            background-color: #505862;
            .ToggleSwitch__switch-inner-circle {
                background-color: #B9BEC6;
            }
        }

        .ToggleSwitch__switch-inner-circle {
            height: 1.9rem;
            width: 1.9rem;
        }
    }

    .KeyboardInputModal__content__toggle.ToggleSwitch--checked {
        background-color: #818A98;
    }

    .App__ActionsMenu__header {
        background-color: #D5D8DD;
    }

    // Use both IDs and classnames here to overpower styles inherited from react-bootstrap's $theme-colors
    #command-block--backward1, .btn-command-block--backward1,
    #command-block--backward2, .btn-command-block--backward2,
    #command-block--backward3, .btn-command-block--backward3,
    #command-block--forward1, .btn-command-block--forward1,
    #command-block--forward2, .btn-command-block--forward2,
    #command-block--forward3, .btn-command-block--forward3,
    #command-block--left45, .btn-command-block--left45,
    #command-block--left90, .btn-command-block--left90,
    #command-block--left180, .btn-command-block--left180,
    #command-block--right45, .btn-command-block--right45,
    #command-block--right90, .btn-command-block--right90,
    #command-block--right180, .btn-command-block--right180,
    // TODO: Add backward commands to the above.
    .command-block:not(.btn-command-block--loop) {
        background-color: #818A98;
        border-color: #818A98;

        svg {
            path, circle, rect {
                stroke: white;
            }
            circle, path.filled {
                fill: white;
            }
        }

        &:focus {
            border: 2px solid white;
            box-shadow: 0 0 0 $c2lc-focus-indicator-width #505862;
        }

        &:hover {
            background-color: #505862;
        }

        &:not(:disabled):not(.disabled):active {
            background-color: #818A98;
            border-color: #818A98;
            box-shadow: 0 0 0 $c2lc-focus-indicator-width #505862;
        }

        &.command-block--pressed {
            border: 3px solid #505862;
        }
    }

    #command-block--loop, .btn-command-block--loop {
        &.command-block--pressed {
            border: 3px solid #505862;
        }
    }

    button.command-block.btn-command-block--startLoop,
    button.command-block.btn-command-block--endLoop {
        background-color: #B9BEC6;
        border: 0.2rem solid transparent;
        .command-block-loop-label-container {
            background-color: #989DA7;
            color: #FFFFFF;
        }
        &:focus {
            border: 0.2rem solid #505862;
            box-shadow: none;
        }
        &:hover {
            background-color: #818A98;
            .command-block-loop-label-container {
                background-color: #626A78;
            }
        }
    }

    .ProgramBlockEditor__loopContainer--focused:not(.ProgramBlockEditor__loopContainer-active-outline) {
        box-shadow: 0 0 0.1px 0.2rem #505862;
    }

    .ProgramBlockEditor__loopContainer--active {
        z-index: 2;
        background-color: #F1F2F4;
    }

    .ProgramBlockEditor__loopContainer-active-outline {
        box-shadow: 0 0 0.1px 0.4rem #505862;
    }

    .ActionsMenuItem {
        &:hover {
            background-color:#D5D8DD;
        }

        &:focus {
            box-shadow: 0 0 0 0.25rem #505862;
        }
    }

    .ActionsMenuItem__icon:not(.ActionsMenuItem__icon--disabled):not(.ActionsMenuItem__icon--loop) {
        background-color: #818A98;
        border-color: #818A98;

        svg {
            path, circle, rect {
                stroke: white;
            }
            circle, path.filled {
                fill: white;
            }
        }
    }

    button.ProgramBlockEditor__program-block--updated.btn-command-block--forward1,
    button.ProgramBlockEditor__program-block--updated.btn-command-block--forward2,
    button.ProgramBlockEditor__program-block--updated.btn-command-block--forward3 {
        @include updatedProgramAnimation(#818A98, 'forward', 'grayscale');
    }

    button.ProgramBlockEditor__program-block--updated.btn-command-block--backward1,
    button.ProgramBlockEditor__program-block--updated.btn-command-block--backward2,
    button.ProgramBlockEditor__program-block--updated.btn-command-block--backward3 {
        @include updatedProgramAnimation(#818A98, 'backward', 'grayscale');
    }

    button.ProgramBlockEditor__program-block--updated.btn-command-block--left45,
    button.ProgramBlockEditor__program-block--updated.btn-command-block--left90,
    button.ProgramBlockEditor__program-block--updated.btn-command-block--left180 {
        @include updatedProgramAnimation(#818A98, 'left', 'grayscale');
    }

    button.ProgramBlockEditor__program-block--updated.btn-command-block--right45,
    button.ProgramBlockEditor__program-block--updated.btn-command-block--right90,
    button.ProgramBlockEditor__program-block--updated.btn-command-block--right180 {
        @include updatedProgramAnimation(#818A98, 'right', 'grayscale');
    }

    .command-block--hidden > svg {
        fill: #818A98;
    }

    .CharacterPositionController {
        border-top: 1px solid #9DA4AF;
    }

    .CharacterPositionController__character-position-button {
        stroke: #67717E;

        &:focus {
            outline: $c2lc-focus-indicator-width solid $c2lc-focus-indicator-color;
        }

        &:hover:not(.CharacterPositionController__character-position-button--disabled) {
            path.filled {
                fill: #3A3F46;

            }
            path:not(.filled) {
                stroke: #3A3F46;
            }
        }
    }

    .CharacterPositionController__character-turn-positions {
        svg path.filled {
            fill: #67717E;
        }
    }

    .CharacterPositionController {
        background-color: #D5D8DD;
    }

    .CharacterPositionController__character-position-button--disabled {
        stroke: #B9BEC6;
    }

    .CharacterPositionController__character-turn-positions {
        .CharacterPositionController__character-position-button--disabled {
            path.filled {
                fill: #B9BEC6;
            }
        }
    }

    .Scene__container {
        background-color: #D5D8DD;
        border: $c2lc-scene-border-width solid #D5D8DD;
    }

    .Scene__header-corner {
        background-color: #D5D8DD;
    }

    .Scene__column-header, .Scene__row-header {
        fill: #D5D8DD;
    }

    .Scene__startIndicator .start-indicator-inner-circle {
        fill: #818A98;
    }

    .Scene__startIndicator .start-indicator-pin {
        fill: #FFFFFF;
    }

    .SceneMessage__closeButton:hover .close-scene-message-background {
        fill: #818A98;
    }

    .SceneMessage__closeButton:hover .close-scene-message-x {
        stroke: #FFFFFF;
    }

    .PenDownToggleSwitch.ToggleSwitch {
        background-color: #B9BEC6;

        .ToggleSwitch__switch-inner-circle {
            background-color: white;
            svg {
                path {
                    stroke: #818A98;
                }
            }
        }

        &:focus {
            box-shadow: 0 0 0 $c2lc-focus-indicator-width #505862;
        }

        &:hover {
            background-color: #505862;
            .ToggleSwitch__switch-inner-circle {
                background-color: #B9BEC6;
            }
        }
    }

    .PenDownToggleSwitch.ToggleSwitch--checked {
        background-color: #818A98;
    }

    .WorldSelector {
        background-color: #D5D8DD;
    }

    .WorldSelector svg.HeaderIcon {
        circle {
            fill: #818A98;
        }
        path {
            stroke: white;
        }
    }

    button.btn.WorldIcon {
        border: 1px solid rgba(0, 0, 0, 0.25);
    }

    .WorldSelector .WorldIcon--selected {
        background-color: #B9BEC6;
    }

    .WorldSelector button.btn.WorldIcon:not(.WorldIcon--disabled):hover {
        background-color: #505862;
    }

    .WorldSelector button.btn.WorldIcon:focus {
        box-shadow:
            0 0 0 0.1rem white,
            0 0 0 0.2rem #505862;

    }

    .WorldSelector button.btn.WorldIcon--disabled {
        background-color: #F1F2F4;
    }

    .AddNode__collapsed-icon {
        background-color: #505862;
        svg path {
            stroke: white;
            stroke-width: 6px;
        }
    }

    .AddNode__expanded-button {
        border: $c2lc-add-node-expanded-border-width solid #505862;
        background-color: inherit;
        box-shadow: none;

        &:focus {
            border: $c2lc-add-node-expanded-border-width solid white;
            box-shadow: 0 0 0 $c2lc-focus-indicator-width #505862;
        }

        svg path {
            stroke: #505862;
        }
    }

    .ProgramBlockEditor__container {
        background-color: #D5D8DD;
        border: 2px solid #D5D8DD;
    }

    .ProgramBlockEditor__add-node-toggle-switch.ToggleSwitch {
        &:focus {
            box-shadow: 0 0 0 $c2lc-focus-indicator-width #505862;
        }

        &:hover {
            background-color: #505862;
            .ToggleSwitch__switch-inner-circle {
                background-color: #B9BEC6;
            }

        }

        svg path {
            stroke: #818A98
        }
    }

    .ProgramBlockEditor__add-node-toggle-switch.ToggleSwitch--checked {
        background-color: #818A98;
    }

    .ProgramBlockEditor__program-deleteAll-button  svg path {
        stroke: white;
        fill: inherit;
    }

    .ProgramBlockEditor__program-deleteAll-button--disabled,
    .ProgramBlockEditor__program-deleteAll-button--disabled:hover {
        svg path {
            stroke: #D5D8DD;
        }
    }

    button.btn.ConfirmDeleteAllModal__option-button {
        background-color: #F1F2F4;
        border-color: #F1F2F4;
        color: black;


        &:hover {
            background: #505862;
            color: White;
        }

        &:focus {
            box-shadow:
                0 0 0 0.1rem white,
                0 0 0 $c2lc-focus-indicator-width #505862
        }

        &.btn-primary {
            &:not(:disabled):not(.disabled):active {
                background-color: #B9BEC6;
            }
        }
    }

    .ProgramBlockEditor__header {
        background-color: #D5D8DD;
    }

    .ProgramBlockEditor__program-sequence-scroll-container {
        background-color: white;
        margin-left: 0.1rem;
    }

    .ProgramBlockEditor__program-sequence-scroll-container--isDragging {
        box-shadow: 0 0 0 0.4rem #505862;
    }

    .ProgramBlockEditor__start-indicator {
        border-color: #818A98;
        background-color: #818A98;
    }

    .ProgramBlockEditor__program-block-connector,
    .ProgramBlockEditor__program-block-connector-loop {
        background-color: #505862;
    }

    button.ProgramBlockEditor__program-block--active:not(.btn-command-block--startLoop) {
        border: 1px solid white;
        box-shadow: 0 0 0 6px #505862;
    }

    button.btn.AddNode__expanded-button.AddNode__expanded-button--isDragTarget {
        box-shadow: 0 0 0 0.4rem #505862;
    }

    .ActionPanel__background {
        svg {
            path {
                fill: #F1F2F4;
                stroke: #D5D8DD;
            }
        }
    }

    button.btn.ActionPanel__action-buttons {
        background-color: #D5D8DD;

        svg {
            path.stroked {
                stroke: #505862;
            }
            path.filled {
                fill: #505862;
            }
        }

        &:hover, &:focus {
            background-color: #505862;

            svg {
                path.stroked {
                    stroke: #F1F2F4;
                }
                path.filled {
                    fill: #F1F2F4;
                }
            }
        }

        &:focus {
            box-shadow:
                0 0 0 0.1rem #30444E,
                0 0 0 $c2lc-focus-indicator-width white,
                0 0 0 0.3rem #30444E;
        }
    }

    button.btn.ActionPanel__action-buttons--disabled {
        svg {
            path.stroked {
                stroke: #F1F2F4;
            }
            path.filled {
                fill: #F1F2F4;
            }
        }
    }

    .ShareModal__form__copyButton {
        background-color: #aaa;
        color: black;

        &:hover {
            background-color:#D5D8DD;
        }

        &:focus {
            box-shadow:
                0 0 0 0.15rem #d5d8dd,
                0 0 0 0.25rem #505862;
        }
    }

    @keyframes pausedGreyscaleAnimation {
        0% {box-shadow: 0 0 0 $c2lc-paused-button-outer-width #505862;}
        50% {box-shadow: 0 0 0 $c2lc-active-button-width #505862;}
        100% {box-shadow: 0 0 0 $c2lc-paused-button-outer-width #505862;}
    }

    button.ProgramBlockEditor__program-block--paused {
        animation-timing-function: ease;
        animation: pausedGreyscaleAnimation 2s infinite;
        border: 1px solid white;
    }

    .App__playAndShare-background {
        background-color: #D5D8DD;
    }

    .RefreshButton svg path:not(.refresh-icon) {
        fill: white;
    }

    .RefreshButton--disabled,
    .RefreshButton--disabled:hover,
    .RefreshButton--disabled:focus {
        svg path:not(.refresh-icon) {
            fill: #D5D8DD;
        }
    }

    button.btn.PlayButton:not(.PlayButton--disabled), .StopButton, .RefreshButton:not(.RefreshButton--disabled) {
        box-shadow: none;

        svg {
            path.inner-circle {
                fill: #FFFFFF;
            }
            circle {
                fill: #67717E;
            }
            path.triangle {
                fill: #1E1E1E;
            }
        }

        &:focus {
            box-shadow: 0 0 0 $c2lc-focus-indicator-width #505862;
            border: none;
        }

        &:hover {
            svg {
                path.inner-circle {
                    fill: #9DA4AF;
                }
                circle {
                    fill: #67717E;
                }
                path.triangle {
                    fill: #1E1E1E;
                }
            }
        }
    }

    button.btn.PlayButton--disabled, .StopButton--disabled, .RefreshButton--disabled {
        background-color: #D5D8DD;

        svg {
            path.inner-circle {
                fill: #D5D8DD;
            }
            circle {
                fill: #9DA4AF;
            }
            path.triangle, path.refresh-icon {
                fill: #67717E;
            }
            rect.refresh-icon {
                fill: #D5D8DD;
            }
        }

        &:focus {
            box-shadow: 0 0 0 $c2lc-focus-indicator-width #505862;
        }
    }

    .StopButton:not(.StopButton--disabled) {
        box-shadow: 0 0 0 0.2rem #67717E;

        svg rect {
            fill: #1E1E1E;
        }

        &:hover {
            background-color: #9DA4AF;
        }

        &:focus {
            box-shadow:
                0 0 0 0.2rem #67717E,
                0 0 0 0.4rem #D5D8DD,
                0 0 0 0.6rem #67717E;
        }
    }

    .StopButton:not(:hover):not(.StopButton--disabled) {
        background-color: #FFFFFF;
    }

    .StopButton--disabled {
        background-color: #D5D8DD;
        box-shadow: 0 0 0 0.2rem #9DA4AF;

        &:focus {
            box-shadow:
                0 0 0 0.2rem #9DA4AF,
                0 0 0 0.3rem #D5D8DD,
                0 0 0 0.5rem #505862;
        }
    }

    .ThemeSelector__option {
        &:focus {
            box-shadow: 0 0 0 $c2lc-focus-indicator-width #505862;
        }
        &:hover {
            background-color: #D5D8DD;
        }
    }

    .ToggleSwitch:not(.ToggleSwitch--checked).SoundOptionsToggle {
        background-color: #B9BEC6;
    }

    .ToggleSwitch.SoundOptionsToggle {
        background-color: #818A98;
        &:hover {
            background-color: #505862;
        }
    }

    .ProgramSpeedController__container svg path {
        fill: #67717E;
    }

    .ProgramSpeedController__slider {
        &::-webkit-slider-runnable-track {
            background-color: #67717E;
            border: 2px solid #67717E;
        }

        &::-webkit-slider-thumb {
            border: 2px solid #67717E;
            background-color: #F1F2F4;
            box-shadow: -8rem 0 0 8rem #F1F2F4;
        }

        &::-moz-range-thumb {
            border-radius: 0;
            border: 2px solid black;
            background-color: #F1F2F4;
            box-shadow: -8rem 0 0 8rem #F1F2F4;
        }

        &::-moz-range-progress {
            background-color: #F1F2F4;
        }
        &::-moz-range-track {
            background-color: #67717E;
        }

        &::-ms-fill-lower {
            background-color: #F1F2F4;
        }

        &::-ms-fill-upper {
            background-color: #67717E;
        }

        &:hover {
            &::-webkit-slider-thumb {
                background-color: #9DA4AF;
                box-shadow: -8rem 0 0 8rem #9DA4AF;
            }
            &::-moz-range-thumb {
                background-color: #9DA4AF;
                box-shadow: -8rem 0 0 8rem #9DA4AF;
            }

            &::-moz-range-progress {
                background-color: #9DA4AF;
            }

            &::-ms-fill-lower {
                background-color: #9DA4AF;
            }
        }

        &:focus {
            outline-color: #505862;
        }
    }


    button.btn.PlayButton--play.PlayButton--disabled {
        background-color: inherit;
        cursor: default;

        svg {
            path.triangle {
                fill: #30444E;
            }
        }
    }

    .App__ShareButton {
        background-color: #F1F2F4;
        color: #1E1E1E;
        svg path {
            fill: #1E1E1E;
        }

        &:hover {
            background: #505862;
            color: #ffffff;
            svg path {
                fill: #ffffff;
            }
        }

        &:focus {
            box-shadow:
                0 0 0 0.15rem #d5d8dd,
                0 0 0 0.25rem #505862;
        }
    }

    .TilePanel__tile:focus {
        outline-color: #505862;
    }

    .TilePanel__tile--selected {
        border-color: #505862;
    }

    .TilePanel__tileInner {
        border-color: #67717E;
    }

    .CustomBackgroundDesignModeButton svg path {
        stroke: #FFFFFF;
    }

    .CustomBackgroundDesignModeButton svg rect {
        fill: #FFFFFF;
    }

    .CustomBackgroundDesignModeButton.IconButton--disabled svg path {
        stroke: #D5D8DD;
    }

    .CustomBackgroundDesignModeButton.IconButton--disabled svg rect {
        fill: #D5D8DD;
    }

    .CustomBackgroundDesignModeButton.IconButton.IconButton--pressed {
        background-color: #937C9C;
    }

    .CustomBackgroundDesignModeButton.IconButton.IconButton--pressed:hover {
        background-color: #505862;
    }

    .CharacterPositionController .set-start-icon-foreground {
        fill: #FFFFFF;
    }

    .CharacterPositionController .IconButton--disabled .set-start-icon-foreground {
        fill: #D5D8DD;
    }

    .CharacterPositionController .paintbrush-icon-foreground {
        stroke: #FFFFFF;
    }

    .CharacterPositionController .IconButton--disabled .paintbrush-icon-foreground {
        stroke: #D5D8DD;
    }
}

/* High Contrast "Mono" Theme */
body.contrast-theme {
    background-color: black;

    .App__container {
        background-color: black;
    }

    .App__header {
        background-color: black;
    }

    .App__header-menu {
        background-color: black;
        border: 2px solid white;
    }

    .App__header-menu > .IconButton {
        box-shadow: 0 0 0 2px black;
    }

    .App__logo-container {
        a:focus {
            border-color: #FFFFFF;
        }

        svg {
            &:hover {
                path.logo-tile {
                    fill: #FFFFFF;
                }
                path.logo-title-letter {
                    stroke: #000000;
                    fill: #000000;
                }
            }
        }
    }

    .App__PrivacyModal__toggle-button {
        color: white;
    }

    .App__PrivacyModal__toggle-button:focus {
        border-color: #ffffff;
    }

    .IconButton.App__header-soundOptions {
        svg path {
            fill: #1E1E1E;
        }
        &:hover {
            svg path {
                fill: #FFFFFF;
            }
        }
    }

    .IconButton {
        background-color: white;

        &:hover:not(.IconButton--disabled) {
            background-color: black;

            .App__header-keyboard-icon {
                path {
                    fill: white;
                }
            }

            .App__header-theme-icon {
                path {
                    fill: white;
                }
            }

            .App__world-selector-icon {
                stroke: white;
                rect,
                path {
                    stroke: white;
                }
                circle {
                    fill: white;
                }
            }

            .App__header-actionsMenu-icon {
                path {
                    stroke: white;
                }
            }
        }

        &:focus {
            box-shadow:
                0 0 0 2px black,
                0 0 0 6px white;
        }

        .App__header-keyboard-icon {
            path {
                fill: black;
            }
        }

        .App__world-selector-icon {
            stroke: black;
            rect,
            path {
                stroke: black;
            }
            circle {
                fill: black;
            }
        }
    }

    .IconButton--disabled {
        background-color: #1E1E1E;

        svg path {
            stroke: #818A98;
        }
    }

    .KeyboardInputModal__content__toggle {
        background-color: white;
        border: 2px solid black;
        padding: 0.2rem;
        color: white;

        &:focus {
            box-shadow: 0 0 0 $c2lc-focus-indicator-width white;
            border: 2px solid black;
        }

        .ToggleSwitch__switch-inner-circle {
            background-color: black;
        }


        &.ToggleSwitch--checked:not(:hover), &:hover {
            background-color: black;
            color: black;
            .ToggleSwitch__switch-inner-circle {
                background-color: white;
            }
        }
    }

    button.btn.ModalWithFooter__secondaryButton,
    button.btn.ModalWithFooter__primaryButton,
    .TextButton {
        background-color: black;
        color: white;

        &:focus {
            box-shadow:
                0 0 0 0.1rem white,
                0 0 0 0.2rem black;
        }

        &:hover {
            background-color: white;
            color: black;
            box-shadow: 0 0 0 0.1rem black;
        }

        &:hover:focus {
            box-shadow: 0 0 0 0.2rem black;
        }
    }

    .KeyboardInputModal__content__toggle.ToggleSwitch {
        background-color: black;
        border: 2px solid white;
        padding: 0.2rem;

        &:hover {
            border: 2px solid black;
            background-color: white;

            .ToggleSwitch__switch-inner-circle {
                background-color: black;
                svg {
                    path {
                        fill: white;
                    }
                }
            }

        }

        &:focus {
            box-shadow: 0 0 0 $c2lc-focus-indicator-width white;
            border: 2px solid black;
        }

        .ToggleSwitch__switch-inner-circle {
            background-color: white;
            svg {
                path {
                    fill: black;
                }
            }
        }
    }

    .App__ActionsMenu__header {
        background-color: white;
    }

    .App__command-palette {
        border: 2px solid white;
    }

    .App__command-palette-command-container {
        background-color:#FFFFFF;

        &::-webkit-scrollbar {
            background-color: black;
        }
        &::-webkit-scrollbar-thumb {
            background-color: black;
            border: 2px solid white;
        }
    }

    .App__command-palette-section {
        background-color: #000000;

        box-shadow: 0px 3px 1px #9DA4AF;
    }

    .App__command-palette-section-heading-container {
        border-bottom: 2px solid #FFFFFF;
    }

    .App__command-palette-section-heading {
        color: #FFFFFF;
    }

    .App__command-palette-command-container .btn-command-block--loop, .ActionsMenuItem__icon--loop {
        svg {
            path.filled-bracket {
                fill: #FFFFFF;
                stroke: #000000;
            }
            path.inner-arrow {
                stroke: #22262A;
            }
        }
    }

    .App__command-palette-command-container .btn-command-block--loop:hover {
        svg {
            path.filled-bracket {
                fill: #000000;
                stroke: #FFFFFF;
            }
            path.inner-arrow {
                stroke: #FFFFFF;
            }
        }
    }

    .App__world-container {
        background-color: black;
    }

    .App__PenDownToggleSwitch-container {
        border-color: #FFFFFF;
    }

    // Use both IDs and classnames here to overpower styles inherited from react-bootstrap's $theme-colors
    #command-block--backward1, .btn-command-block--backward1,
    #command-block--backward2, .btn-command-block--backward2,
    #command-block--backward3, .btn-command-block--backward3,
    #command-block--forward1, .btn-command-block--forward1,
    #command-block--forward2, .btn-command-block--forward2,
    #command-block--forward3, .btn-command-block--forward3,
    #command-block--left45, .btn-command-block--left45,
    #command-block--left90, .btn-command-block--left90,
    #command-block--left180, .btn-command-block--left180,
    #command-block--right45, .btn-command-block--right45,
    #command-block--right90, .btn-command-block--right90,
    #command-block--right180, .btn-command-block--right180,
    // TODO: Add backward commands to the above.
    btn.command-block {
        border-color: white;
        background-color: white;

        svg {
            path, circle {
                stroke: black;
            }
            circle, path.filled {
                fill: black;
            }
        }

        &:hover {
            border: 2px solid white;
            border-radius: 0.2rem;
            background-color: black;

            svg {
                path, rect, circle {
                    stroke: white;
                }
                circle, path.filled {
                    fill: white;
                }
            }
        }

        &:focus:hover {
            box-shadow: 0 0 0 $c2lc-focus-indicator-width white;
        }

        &:focus:not(:hover),
        &:active,
        &:not(:disabled):not(.disabled):active,
        &.command-block--pressed,
        &.command-block--pressed:hover,
        &.command-block--pressed:active {
            padding: calc(0.2rem - 2px);
            border: 2px solid black;
            border-color: black;
            box-shadow: 0 0 0 $c2lc-focus-indicator-width white;
        }

        &:not(:disabled):not(.disabled):active {
            background-color: black;
        }
    }

    button.command-block.btn-command-block--startLoop,
    button.command-block.btn-command-block--endLoop {
        background-color: #FFFFFF;
        .command-block-loop-label-container {
            background-color: #FFFFFF;
            color: black;
        }
        &:focus {
            padding: calc(0.2rem - 2px);
            border: 2px solid #000000;
            box-shadow: 0 0 0.1px 0.2rem #FFFFFF;
        }
        &:hover {
            padding: calc(0.2rem - 2px);
            border: 2px solid #FFFFFF;
            box-shadow: 0 0 0.1px 0.1rem #FFFFFF;
            background-color: black;
            .command-block-loop-label-container {
                background-color: black;
                color: #FFFFFF;
            }
        }
    }

    .ProgramBlockEditor__loopContainer--focused:not(.ProgramBlockEditor__loopContainer-active-outline) {
        box-shadow: 0 0 0.1px 0.2rem #FFFFFF;
    }

    .ProgramBlockEditor__loopContainer--active {
        z-index: 2;
        background-color: #205493;
    }

    .ProgramBlockEditor__loopContainer-active-outline {
        border: 0.2rem solid #000000;
        box-shadow: 0 0 0.1px 0.4rem #FFFFFF;
    }

    .ActionsMenuItem {
        &:focus {
            box-shadow: 0 0 0 0.2rem black;
        }

        &:hover {
            background-color: #1E1E1E;
            color: white;
        }

        &:hover:focus {
            box-shadow:
                0 0 0 0.1rem white,
                0 0 0 0.2rem black;
        }
    }

    .ActionsMenuItem__icon:not(.ActionsMenuItem__icon--disabled):not(.ActionsMenuItem__icon--loop) {
        background-color: white;
        border: 1px solid black;

        svg {
            path, circle {
                stroke: black;
            }
            circle, path.filled {
                fill: black;
            }
        }
    }

    button.ProgramBlockEditor__program-block--updated.btn-command-block--forward1,
    button.ProgramBlockEditor__program-block--updated.btn-command-block--forward2,
    button.ProgramBlockEditor__program-block--updated.btn-command-block--forward3 {
        @include updatedProgramAnimation(white, 'forward', 'highcontrast');
    }

    button.ProgramBlockEditor__program-block--updated.btn-command-block--backward1,
    button.ProgramBlockEditor__program-block--updated.btn-command-block--backward2,
    button.ProgramBlockEditor__program-block--updated.btn-command-block--backward3 {
        @include updatedProgramAnimation(white, 'backward', 'highcontrast');
    }

    button.ProgramBlockEditor__program-block--updated.btn-command-block--left45,
    button.ProgramBlockEditor__program-block--updated.btn-command-block--left90,
    button.ProgramBlockEditor__program-block--updated.btn-command-block--left180 {
        @include updatedProgramAnimation(white, 'left', 'highcontrast');
    }

    button.ProgramBlockEditor__program-block--updated.btn-command-block--right45,
    button.ProgramBlockEditor__program-block--updated.btn-command-block--right90,
    button.ProgramBlockEditor__program-block--updated.btn-command-block--right180 {
        @include updatedProgramAnimation(white, 'right', 'highcontrast');
    }

    .command-block--hidden > svg {
        fill: white;
    }

    button.ProgramBlockEditor__program-block--active:not(.btn-command-block--startLoop) {
        box-shadow: 0 0 0 $c2lc-focus-indicator-width white;
        border: 2px solid black;
        border-color: black;
    }

    .App__command-palette-heading {
        color: white;
    }

    .App__world-container {
        border: 2px solid white;
    }

    .CharacterPositionController {
        background-color: black;
        border-top: 1px solid white;
    }

    .CharacterPositionController__character-position-button {
        &:focus {
            outline: $c2lc-focus-indicator-width solid white;
        }
    }

    .CharacterPositionController__character-position-button:not(.CharacterPositionController__character-position-button--disabled) {
        stroke: white;

        &:hover {
            path:not(.inner):not(.filled) {
                stroke: white;
                stroke-width: 4px;
            }
            path.inner {
                stroke: black;
            }
        }
    }

    .CharacterPositionController__character-turn-positions {
        .CharacterPositionController__character-position-button{
            path.filled {
                fill: white;
                stroke: white;
            }
        }

        .CharacterPositionController__character-position-button:not(.CharacterPositionController__character-position-button--disabled) path.filled {
            fill: white;
        }

        .CharacterPositionController__character-position-button--disabled {
            path.filled {
                fill: #505862;
                stroke: #505862;
            }
        }

        .CharacterPositionController__character-position-button:not(.CharacterPositionController__character-position-button--disabled) {
            &:hover {
                path.filled {
                    fill: black;
                    stroke: white;
                }
            }
        }
    }

    .CharacterPositionController__character-position-button--disabled {
        stroke: #505862;
    }

    .ProgramBlock__character-position-coordinate-box:focus {
        outline: $c2lc-focus-indicator-width solid white;
    }

    .Scene__container {
        background-color: white;
        border: $c2lc-scene-border-width solid white;
    }

    .Scene {
        background-color: black;
    }

    .Scene__header-corner {
        background-color: white;
    }

    .Scene__column-header, .Scene__row-header {
        fill: white;
    }

    .Scene__startIndicator .start-indicator-inner-circle {
        fill: #FFFFFF;
    }

    .Scene__startIndicator .start-indicator-pin {
        fill: #1E1E1E;
    }

    .SceneMessage__closeButton:hover .close-scene-message-background {
        fill: #1E1E1E;
    }

    .SceneMessage__closeButton:hover .close-scene-message-x {
        stroke: #FFFFFF;
    }

    .PenDownToggleSwitch, .PenDownToggleSwitch.ToggleSwitch--checked:hover {
        background-color: white;
        border: 1px solid black;
        .ToggleSwitch__switch-inner-circle {
            background-color: black;
            svg {
                path {
                    stroke: white;
                }
            }
        }
    }

    .PenDownToggleSwitch:focus {
        box-shadow: 0 0 0 $c2lc-focus-indicator-width white;
        border: none;
    }

    .PenDownToggleSwitch:hover, .PenDownToggleSwitch.ToggleSwitch--checked {
        background-color: black;
        border: 2px solid white;
        padding: 0.2rem;
        .ToggleSwitch__switch-inner-circle {
            background-color: white;
            svg {
                path {
                    stroke: black;
                }
            }
        }
    }

    .WorldSelector {
        background-color: black;

        svg.HeaderIcon {
            circle {
                fill: white;
            }
        }

        button.btn.WorldIcon:not(.WorldIcon--disabled):hover {
            background-color: black;
            border: 1px solid white;
        }

        button.btn:hover:not(.WorldIcon--disabled) svg {
            stroke: white;
        }

        .WorldIcon--selected:not(.WorldIcon--disabled),
        button.btn:focus {
            background-color: white;
            box-shadow: 0 0 0 0.2rem white;
            border: 0.2rem solid black;
        }

        button.btn.WorldIcon--disabled {
            background-color: #505862;
        }
    }

    .ProgramBlockEditor__container {
        border: 2px solid #F1F1F1;
    }

    .ProgramBlockEditor__header {
        background-color: white;
        color: black;
    }

    .ProgramBlockEditor__add-node-toggle-switch, div.ProgramBlockEditor__add-node-toggle-switch.ToggleSwitch--checked:hover {
        background-color: black;

        .ToggleSwitch__switch-inner-circle {
            background-color: white;
            svg {
                path {
                    stroke: black;
                }
            }
        }

        &:focus {
            border: 2px solid white;
            padding: 0.2rem;
        }
    }

    .ProgramBlockEditor__add-node-toggle-switch.ToggleSwitch--checked, .ProgramBlockEditor__add-node-toggle-switch.ToggleSwitch:hover {
        background-color: white;
        border: 2px solid black;
        padding: 0.2rem;

        .ToggleSwitch__switch-inner-circle {
            background-color: black;
            svg {
                path {
                    stroke: white;
                }
            }
        }

        &:focus {
            border: 2px solid black;
        }
    }

    .ProgramBlockEditor__program-deleteAll-button {
        background-color: black;
        border: 2px solid white;
        svg path {
            stroke: white;
        }

        &:hover:not(.ProgramBlockEditor__program-deleteAll-button--disabled) {
            background-color: white;
            border: 2px solid black;
            svg path {
                stroke: black;
                fill: inherit;
            }
        }

        &:focus {
            box-shadow: 0 0 0 $c2lc-focus-indicator-width black;
        }
    }

    .ProgramBlockEditor__program-deleteAll-button--disabled,
    .ProgramBlockEditor__program-deleteAll-button--disabled:hover {
        background-color: white;
        border: 2px solid black;
        svg path {
            stroke: #818A98;
            fill:inherit;
        }
    }

    button.btn.ConfirmDeleteAllModal__option-button {
        background-color: black;
        color: white;
        border-color: white;

        &:focus {
            box-shadow:
                0 0 0 0.1rem white,
                0 0 0 0.2rem black;
        }

        &:hover {
            background-color: white;
            color: black;
            box-shadow: 0 0 0 0.1rem black;
        }

        &:hover:focus {
            box-shadow: 0 0 0 0.2rem black;
        }

        &.btn-primary {
            &:not(:disabled):not(.disabled):active {
                background-color: white;
                color: black;
            }
        }
    }

    .ProgramBlockEditor__program-sequence-scroll-container {
        background-color: black;
    }

    .ProgramBlockEditor__program-sequence-scroll-container--isDragging {
        box-shadow: 0 0 0 0.2rem black,
        0 0 0 0.4rem white,
        0 0 0 0.6rem black;
    }

    .ProgramBlockEditor__start-indicator {
        border-color: white;
        background-color: white;
    }

    .ProgramBlockEditor__program-block-connector,
    .ProgramBlockEditor__program-block-connector-loop {
        background-color: white;
    }

    .ProgramBlockEditor__program-block-connector-loop--active-outline {
        display: none;
    }

    .AddNode__collapsed-icon {
        background-color: white;
        svg path {
            stroke-width: 6px;
            stroke: black;
            fill: black;
        }
    }

    button.btn.AddNode__expanded-button.AddNode__expanded-button--isDragTarget {
        box-shadow: 0 0 0 0.45rem white;
    }

    .AddNode__expanded-button {
        border: $c2lc-add-node-expanded-border-width solid white;
        background-color: black;
        box-shadow: none;
        svg path {
            stroke: white;
            fill: white
        }

        &:focus {
            box-shadow: 0 0 0 $c2lc-focus-indicator-width white;
        }

        &:hover {
            svg path {
                stroke: black;
                fill: black;
            }
        }
    }

    .ActionPanel__background {
        svg {
            path {
                fill: white;
                stroke: white;
            }
        }
    }

    button.btn.ActionPanel__action-buttons {
        background-color: black;

        svg {
            path.stroked {
                stroke: white;
            }
            path.filled {
                fill: white;
            }
        }

        &:hover {
            border: 1px solid black;
            background-color: white;
            svg {
                path.stroked {
                    stroke: black;
                }
                path.filled {
                    fill: black;
                }
            }
        }

        &:focus {
            box-shadow:
                0 0 0 0.1rem #30444E,
                0 0 0 $c2lc-focus-indicator-width white,
                0 0 0 0.3rem #30444E;
        }
    }

    button.btn.ActionPanel__action-buttons--disabled {
        svg {
            path.stroked {
                stroke: #505862;
            }
            path.filled {
                fill: #505862;
            }
        }
    }

    @keyframes pausedContrastAnimation {
        0% {box-shadow: 0 0 0 $c2lc-paused-button-outer-width white;}
        50% {box-shadow: 0 0 0 $c2lc-active-button-width white;}
        100% {box-shadow: 0 0 0 $c2lc-paused-button-outer-width white;}
    }

    /* Note that this rule applies to both loop blocks and non-loop blocks */
    button.ProgramBlockEditor__program-block--paused {
        padding: calc(0.2rem - 1px);
        border: 1px solid black;
        animation-timing-function: ease;
        animation: pausedContrastAnimation 2s infinite;
    }

    .App__playAndShare-background {
        background-color: #FFFFFF;
    }

    .App__shareButton-container button {
        background-color: #1E1E1E;
        color: #FFFFFF;
    }


    .RefreshButton {
        svg {
            path:not(.refresh-icon) {
                fill: white;
            }
            circle {
                fill:black;
            }
        }

        &:hover:not(.RefreshButton--disabled) {
            &:not(:focus) {
                box-shadow: 0 0 0 2px white;
            }

            svg {
                .refresh-icon {
                    fill: white;
                }
                path.inner-circle {
                    fill: #1E1E1E;
                }
                circle {
                    fill: black;
                }
            }
        }

        &:focus {
            box-shadow: 0 0 0 $c2lc-focus-indicator-width #1E1E1E;
        }
    }

    button.btn.PlayButton:not(.PlayButton--disabled) {
        &:hover {
            svg {
                path.inner-circle {
                    fill: #1E1E1E;
                }
                path.triangle {
                    fill: #FFFFFF;
                }
            }
        }
        &:focus {
            box-shadow: 0 0 0 $c2lc-focus-indicator-width #1E1E1E;
        }

        svg {
            path.inner-circle {
                fill: white;
            }
        }
    }

    button.btn.PlayButton, .StopButton {
        svg {
            fill: #FFFFFF;
            circle, path.triangle {
                fill: #1E1E1E;
            }
        }
    }

    button.btn.PlayButton--pause:not(.PlayButton--disabled) {
        &:hover {
            svg {
                circle {
                    fill: #FFFFFF;
                }
                path.inner-circle {
                    fill: #1E1E1E;
                }
            }
        }
        &:focus {
            box-shadow: 0 0 0 $c2lc-focus-indicator-width #1E1E1E;
        }
    }

    button.btn.PlayButton--play.PlayButton--disabled, .StopButton--disabled, .RefreshButton--disabled {
        svg {
            path.inner-circle {
                fill: #FFFFFF;
            }
            circle, path.triangle, rect, path.refresh-icon {
                fill: #8E8E8E;
            }
        }
        &:focus {
            box-shadow: 0 0 0 $c2lc-focus-indicator-width #1E1E1E;
        }
    }

    .RefreshButton--disabled {
        svg {
            rect.refresh-icon {
                stroke: #8E8E8E;
                fill: #FFFFFF;
            }
            line.refresh-icon {
                stroke: #8E8E8E;
            }
            .refresh-icon {
                stroke: #FFFFFF;
            }
        }
    }

    .StopButton {
        box-shadow: 0 0 0 0.2rem #1E1E1E;

        svg rect {
            fill: #FFFFFF;
        }

        &:hover:not(.StopButton--disabled) {
            box-shadow:
                0 0 0 0.2rem #1E1E1E,
                0 0 0 0.4rem white;

            svg rect {
                fill: #ffffff
            }
            background-color: #1E1E1E;
        }

        &:focus {
            box-shadow:
                0 0 0 0.2rem #1E1E1E,
                0 0 0 0.4rem #FFFFFF,
                0 0 0 0.6rem #1E1E1E;
        }
    }

    .StopButton:not(:hover):not(.StopButton--disabled) {
        background-color: #FFFFFF;
        svg rect {
            fill: #1E1E1E;
        }
    }

    .StopButton--disabled {
        background-color: #FFFFFF;
        box-shadow: 0 0 0 0.2rem #8E8E8E;

        &:focus {
            box-shadow:
                0 0 0 0.2rem #8E8E8E,
                0 0 0 0.4rem #FFFFFF,
                0 0 0 0.6rem #1E1E1E;
        }

        svg rect {
            fill: #8E8E8E;
        }
    }

    .ThemeSelector__option {
        &:hover {
            color: white;
            background-color: black;
        }

        &:hover:focus {
            box-shadow:
                0 0 0 0.1rem white,
                0 0 0 0.2rem black;
        }
    }

    .ToggleSwitch:not(.ToggleSwitch--checked).SoundOptionsToggle {
        box-shadow: 0 0 0 0.1rem #1E1E1E;
        background-color: #FFFFFF;
        .ToggleSwitch__switch-inner-circle {
            background-color: #1E1E1E;
        }
        &:hover {
            background-color: #1E1E1E;
            .ToggleSwitch__switch-inner-circle {
                background-color: #FFFFFF;
            }
        }
        &:focus {
            box-shadow:
                0 0 0 0.1rem #1E1E1E,
                0 0 0 0.3rem #FFFFFF,
                0 0 0 0.5rem #1E1E1E
        }
    }

    .ToggleSwitch.SoundOptionsToggle {
        box-shadow: 0 0 0 0.1rem #FFFFFF;
        background-color: #1E1E1E;
        .ToggleSwitch__switch-inner-circle {
            background-color: #FFFFFF;
        }
        &:hover {
            box-shadow: 0 0 0 0.1rem #1E1E1E;
            background-color: #FFFFFF;
            .ToggleSwitch__switch-inner-circle {
                background-color: #1E1E1E;
            }
        }
        &:focus {
            box-shadow:
                0 0 0 0.1rem #1E1E1E,
                0 0 0 0.3rem #FFFFFF,
                0 0 0 0.5rem #1E1E1E
        }
    }

    .ProgramSpeedController__slider {
        &::-webkit-slider-runnable-track {
            background-color: black;
            border: 2px solid black;
        }

        &::-webkit-slider-thumb {
            border: 2px solid black;
            background-color: #0078FF;
            box-shadow: -8rem 0 0 8rem #0078FF;
        }

        &::-moz-range-thumb {
            border-radius: 0;
            border: 2px solid black;
            background-color: #0078FF;
            box-shadow: -8rem 0 0 8rem #0078FF;
        }

        &::-moz-range-progress {
            background-color: #0078FF;
        }

        &::-moz-range-track {
            background-color: black;
        }

        &::-ms-fill-lower {
            background-color: #0078FF;
        }

        &::-ms-fill-upper {
            background-color: black;
        }

        &:hover {
            &::-webkit-slider-thumb {
                background-color: #FF0000;
                box-shadow: -8rem 0 0 8rem #FF0000;
            }
            &::-moz-range-thumb {
                background-color: #FF0000;
                box-shadow: -8rem 0 0 8rem #FF0000;
            }

            &::-moz-range-progress {
                background-color: #FF0000;
            }

            &::-ms-fill-lower {
                background-color: #FF0000;
            }
        }

        &:focus {
            outline-color: #1E1E1E;
        }
    }

    .App__ShareButton {
        &:focus {
            box-shadow:
                0 0 0 0.1rem white,
                0 0 0 0.2rem black;
        }

        &:hover {
            background-color: white;
            color: black;
            box-shadow: 0 0 0 0.1rem black;
            svg path {
                fill: black;
            }

        }

        &:hover:focus {
            box-shadow: 0 0 0 0.2rem black;
        }
    }

    .App__ShareButton__icon {
        path {
            fill: white;
        }
    }


    .ShareModal__form__copyButton {
        background-color: black;
        color: white;

        &:focus {
            box-shadow:
                0 0 0 0.1rem white,
                0 0 0 0.2rem black;
        }

        &:hover {
            background-color: white;
            color: black;
            box-shadow: 0 0 0 0.1rem black;
        }

        &:hover:focus {
            box-shadow: 0 0 0 0.2rem black;
        }
    }

    .TilePanel__tile:focus {
        outline-color: white;
    }

    .TilePanel__tile--selected {
        border-color: white;
    }

    .TilePanel__tileInner {
        border-color: #FFFFFF;
    }

    .CustomBackgroundDesignModeButton:hover:not(.IconButton--disabled) svg path {
        stroke: #FFFFFF;
    }

    .CustomBackgroundDesignModeButton:hover:not(.IconButton--disabled) svg rect {
        fill: #FFFFFF;
    }

    .CustomBackgroundDesignModeButton.IconButton.IconButton--pressed {
        background-color: #4998F0;
    }

    .CustomBackgroundDesignModeButton.IconButton.IconButton--pressed:hover {
        background-color: #1E1E1E;
    }

    .CharacterPositionController .IconButton:hover:not(.IconButton--disabled) .set-start-icon-foreground {
        fill: #FFFFFF;
    }

    .CharacterPositionController .IconButton:hover:not(.IconButton--disabled) .paintbrush-icon-foreground {
        stroke: #FFFFFF;
    }

    .paintbrush-cursor-brush-outline {
        stroke: #ff0000;
    }
}
