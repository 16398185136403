@import 'variables';

.ModalWithFooter__footer {
    display: flex;
    column-gap: 1rem;
    padding: 1.5rem 1rem 1.5rem 5rem;
    justify-content: flex-end;
    border-top: 2px solid #F1F2F4;
}

