@import '_variables';

.ShareModal__content {
    padding: 2rem;
    border-bottom: 1px solid #E4E7EB;
}

.ShareModal__form {
    display: grid;
    grid-template-columns: 1fr min-content;    
    column-gap: 1rem;
}

.ShareModal__form__URL {
    text-overflow: ellipsis;
}

.ShareModal__form__copyButton {
    padding: 0.5rem 2rem;
    align-items: center;
    white-space: nowrap;
}