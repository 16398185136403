@import 'variables';

.WorldSelector__content {
    padding: 0;
}

.WorldSelector__prompt {
    padding: 1.5rem 0 1.5rem 2.3rem;
}

.WorldSelector__options {
    display: grid;
    padding: 1rem 1rem 1rem 2rem;
    width: fit-content;
    grid-template-columns: repeat(3, 7rem);
    grid-auto-rows: 1fr;
    column-gap: 1.5rem;
    row-gap: 0.4rem;
    background-color: #F1F2F4;
}

.WorldSelector__option-image {
    display: inline-block;
    border-radius: 10px;
    padding: 0.3rem;
    svg {
        width: 5rem;
        height: 5rem;
    }
}

.WorldSelector__option-image--selected {
    box-shadow: 0 0 0 $c2lc-focus-indicator-width #000;
}

.WorldSelector__option-row {
    display: grid;
    padding: 0.5rem 0 0 0.3rem;
    grid-template-columns: max-content 1fr;
    column-gap: 0.1rem;
    align-items: first baseline;
}
