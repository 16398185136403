.ActionsSimplificationModal__menu {
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 0;
    padding: 1rem 2rem;
    border-bottom: 1px solid #F1F2F4;
}

.ActionsSimplificationModal__section-heading {
    font-size: 1rem;
    padding: 1.2rem;
}

.ActionsSimplificationModal__section {
    background-color: #F1F2F4;
}
