@import 'variables';

.ActionPanel__background {
    position: absolute;
    top: -0.3rem;

    svg {
        width: $c2lc-action-panel-width;
        path {
            fill: #30444E;
            stroke: #4C9990;
        }
    }
}

.ActionPanel__panel {
    align-items: center;
    background-color: transparent;
    display: flex;
    height: $c2lc-action-panel-height;
    justify-content: space-evenly;
    padding-left: 2.4rem;
    padding-right: 2.4rem;
    position: absolute;
    width: $c2lc-action-panel-width;
}

.ActionPanel__panel--hidden {
    display: none;
}

button.btn.ActionPanel__action-buttons {
    background-color: #4C9990;
    border: none;
    border-radius: 50%;
    height: 2.5rem;
    padding: 0.1rem;
    margin: 0.2rem;
    width: 2.5rem;

    svg {
        height: 1.4rem;
        width: 1.4rem;
        path.stroked {
            stroke: #1e1e1e;
        }
        path.filled {
            fill: #1e1e1e;
        }
    }

    &:hover {
        background-color: #F1AE5B;
    }

    &:focus {
        box-shadow:
            0 0 0 0.1rem #30444E,
            0 0 0 $c2lc-focus-indicator-width white;
    }
}

button.btn.ActionPanel__action-buttons--disabled {
    svg {
        path.stroked {
            stroke: #9DA4AF;
        }
        path.filled {
            fill: #9DA4AF;
        }
    }
}

.ActionPanel__action-button-svg {
    position: relative;
    margin: auto;
    display: block;
}
