@import 'variables';

$widthOfCollapsedIcon: 0.8rem;
$heightOfCollapsedIcon: 0.8rem;
$widthOfExpandedIcon: 1.25rem;
$heightOfExpandedIcon: 1.25rem;

$addNodeExpandedButtonPadding: 0.5rem;

$addNodeDropMargin: 2.5rem;
$addNodeDropAreaHeight: 6.25rem;

button.btn.AddNode__expanded-button {
    border: $c2lc-add-node-expanded-border-width solid #D5D8DD;
    border-radius: 50%;
    background-color:  #22343C;
    padding: $addNodeExpandedButtonPadding;
    position: relative;
    z-index: 2;
    width: 45px;
    height: 45px;

    svg {
        width: $widthOfExpandedIcon;
        height: $heightOfExpandedIcon;
        display: block;
        path {
            stroke: white;
        }
    }

    &:focus {
        border: $c2lc-add-node-expanded-border-width solid transparent;
        box-shadow: 0 0 0 $c2lc-focus-indicator-width white;
    }

    &:hover {
        box-shadow: 0 0 0 $c2lc-hover-indicator-width $c2lc-focus-indicator-color;
        background-color: #e5e5e5;
    }
}

button.btn.AddNode__expanded-button.AddNode__expanded-button--isDragTarget {
    box-shadow: 0 0 0 0.4rem #53A28C;
}

.AddNode--is-dragging-command .AddNode__expanded-button {
    pointer-events: none;
}

.AddNode__collapsed-icon {
    background-color: #D5D8DD;
    height: $heightOfCollapsedIcon;
    width: $widthOfCollapsedIcon;
    padding: 0.225rem;
    border-radius: 50%;

    svg {
        height: 0.375rem;
        width: 0.375rem;
        display: block;
        stroke: black;
        stroke-width: 2.1px;
    }
}
