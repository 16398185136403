/* Colors for default theme. */
$c2lc-primary-button-color: #FFFFFF;

$c2lc-forward-command-color: #52BDA4;
$c2lc-forward-command-hover-color: #4C9990;

$c2lc-backward-command-color: #5CBCD1;
$c2lc-backward-command-hover-color: #4292A4;

$c2lc-left-command-color: #F5C58A;
$c2lc-left-command-hover-color: #F1AE5B;

$c2lc-right-command-color: #F3988B;
$c2lc-right-command-hover-color: #EA462F;

$c2lc-focus-indicator-color: #1E1E1E;

$c2lc-disabled-background-color: #B9BEC6;
$c2lc-disabled-foreground-color: #F1F2F4;

/* Borders */

$c2lc-button-border-width: 3px;
$c2lc-active-button-width: 6px;
$c2lc-paused-button-outer-width: 8px;

/* Focus Indicator */

$c2lc-focus-indicator-width: 0.2rem;

/* Hover Indicator */

$c2lc-hover-indicator-width: 0.1rem;

/* Action Panel */

$c2lc-action-panel-width: 16rem;
$c2lc-action-panel-height: 3rem;

/* Scene */

$c2lc-scene-height: 600px;
$c2lc-scene-width: 900px;
$c2lc-scene-col-header-height: 30px;
$c2lc-scene-row-header-width: 30px;
$c2lc-scene-space-for-scrollbar: 20px;
$c2lc-scene-border-width: 2px;
$c2lc-app-scene-container-margin-right: 0.25rem;

/* Add Node */

$c2lc-add-node-expanded-border-width: 2px;

/* mixins */

@mixin updatedProgramAnimation($color, $commandName, $theme) {
    @keyframes updatedAnimation-#{$commandName}-#{$theme} {
        0% {box-shadow: 0 0 0 1rem $color;}
        100% {box-shadow: 0 0 0 0 $color;}
    }
    animation: updatedAnimation-#{$commandName}-#{$theme} 1.5s;
    animation-timing-function: ease;
};
